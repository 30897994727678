import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../layout.js"

export default function Portfolio() {
  return (
    <Layout>
      <Container>
        <ParaCont>
          <FuturaTitle>Work</FuturaTitle>

          <div className="padded">
            I have worked for several different employers and clients over the
            years, among them:
          </div>
          <Para id="#askphill">
            <a href="https://www.askphill.com">@AskPhill</a>
            <p>
              Tumble 'N Dry: Front-end Development & UX design for a Children's
              clothing store.
            </p>
            <p>
              Developing different interactive cart-enhancing features and
              campaign related pages with JavaScript and Liquid.
            </p>
          </Para>
          <Para id="#bayer">
            <a href="https://www.bayer.com">@Bayer Pharmaceuticals</a>
            <p>
              As a Digital Excellence Manager, maintaining a content platform
              for medical specialists, Online Marketing, Analytics, UX for a
              Pharmaceutical company
            </p>
            <p>
              Increasing the effectiveness of the Analytics set-up, writing
              user-stories for a global brand transition, and managing the
              Health Care Professional platform with new content as it relates
              to several of Bayer's therapeutic specialisms.
            </p>
          </Para>
          <Para id="#bh">
            <a href="https://www.buildingheroes.nl">@Building Heroes</a>
            <p>
              Online Marketing / Product Ownership / UX Design for a Staffing
              Agency in Construction
            </p>
            <p>
              Executing online marketing campaigns for attracting quality
              candidates and traffic; creating assets for online marketing;
              improving current website UX and aiding in the conception of new
              platform initiatives: &nbsp;
              <a href="https://constructionuniversity.nl/">
                Construction University
              </a>
              .
            </p>
          </Para>
          <Para id="#unet">
            <a href="https://www.eurofiber.com">@UNET</a> Online Marketing /
            <p>
              Product Ownership / UX Design for a Telecommunications company
            </p>
            <p>
              Improving the e-commerce experience for B2B-customers and
              Conversion-Rate Optimization with the help of the development
              team; managing and executing online campaigns for increasing
              quality traffic to the website.
            </p>
          </Para>
          <Para id="#ipg">
            <a href="https://www.ipgmediabrands.com">@IPG Mediabrands</a>
            Programmatic and Digital Campaign Management for various sectors
            like Banking (ABN AMRO), Energy companies(Vattenfall, Exxon) and
            Retail companies (Dr. Oetker, Coca Cola).
          </Para>
        </ParaCont>
      </Container>
      <Footer></Footer>
    </Layout>
  )
}

const Container = styled.section`
  background: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  color: black;
  height: auto;
  margin: 0 auto;
  width: 50%;
  font-size: 0.9em;
  // background: red;
  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
  }
  a {
    color: #f2f2f2;
    text-decoration: none;
    font-size: 1.3rem;
    font-family: var(--main-font);
    //padding: 10px;
    padding-left: 0px;
    font-weight: bolder;
  }
`

const ParaCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .padded {
    padding: 20px;
  }
`

const FuturaTitle = styled.h2`
  color: black;
  font-family: var(--main-font);
  padding: 25px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 800;
  font-size: 4em;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
    font-size: 1.4rem;
    padding: 0px;
    padding-bottom: 2vh;
  }
`

const Para = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 35px;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  width: 35vw;
  color: #f2f2f2;
  max-height: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  background: #252525;
  a {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 1em;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
`

const Footer = styled.div`
  height: 10vh;
  background: #f2f2f2;
`
